<template>
    <div class="devices pt-2">
        <h3 class="ml-2 info-text">Устройства</h3>
        <div class="skeletons" v-if="!isLoaded" >
            <b-card v-for="index in deviceCount" :key="index" class="mt-2">
                <b-row>
                    <b-col class="col-md-2 col-sm-2 col-lg-2 col-xs-2 col-xl-2">
                        <b-skeleton animation="fade" width="85%"></b-skeleton>
                    </b-col>
                    <b-col class="icon-info-box col-md-2 col-sm-2 col-lg-2 col-xs-2 col-xl-2">
                        <b-skeleton animation="fade" width="85%"></b-skeleton>
                    </b-col>
                    <b-col class="icon-info-box col-md-2 col-sm-2 col-lg-2 col-xs-2 col-xl-2">
                        <b-skeleton animation="fade" width="85%"></b-skeleton>
                    </b-col>
                    <b-col class="icon-info-box col-md-2 col-sm-2 col-lg-2 col-xs-2 col-xl-2">
                        <b-skeleton animation="fade" width="85%"></b-skeleton>
                    </b-col>
                    <b-col class="col-md-4 col-sm-2 col-lg-4 col-xs-2 col-xl-4 text-right no-show-on-mobile">
                        <b-skeleton animation="fade" width="85%"></b-skeleton>
                    </b-col>
                </b-row>
                <b-row class="mt-2 align-items-center justify-content-center">
                    <b-col class="col-12 col-sm-12 col-md-8 col-lg-2 col-xl-2">
                        <b-row>
                            <b-col class="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                                <dl>
                                    <dd><b-skeleton animation="fade" width="85%"></b-skeleton></dd>
                                    <dt><b-skeleton animation="fade" width="55%"></b-skeleton></dt>
                                </dl>
                            </b-col>
                            <b-col class="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                                <dl>
                                    <dd><b-skeleton animation="fade" width="85%"></b-skeleton></dd>
                                    <dt><b-skeleton animation="fade" width="55%"></b-skeleton></dt>
                                </dl>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                        <b-skeleton-img no-aspect height="120px"></b-skeleton-img>
                    </b-col>
                    <b-col class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 text-right mt-2">
                        <b-button-group>
                            <b-skeleton type="button" class="ml-2"></b-skeleton>
                            <b-skeleton type="button" class="ml-2"></b-skeleton>
                            <b-skeleton type="button" class="ml-2"></b-skeleton>
                        </b-button-group>
                    </b-col>
                </b-row>
            </b-card>
        </div>
        <div v-if=isAdmin>
            <b-form-checkbox v-model="detailed_view" name="detailed_view" switch>
             <small>Детайли</small>
            </b-form-checkbox>
            <div v-if="!detailed_view">
                <b-row class="text-center">
                    <b-col class="mt-2 col-md-6 col-sm-12 col-lg-6 col-xs-12 col-xl-6">
                        <b-card title="Производство в реално време [kW]">
                            <frontEndLineChartUser />
                        </b-card>
                    </b-col>
                    <b-col class="mt-2 col-md-6 col-sm-12 col-lg-6 col-xs-12 col-xl-6">
                        <b-card title="Производство за последните 7 дни [kWh]">
                            <frontEndBarChartUser />
                        </b-card>
                    </b-col>
                </b-row>
                <b-table striped hover v-if="isLoaded" :items="device_table" :fields="fields" small class="mt-2" :tbody-tr-class="rowClass"></b-table>
                <b-row>
                    <b-col class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">Инвертори в норма: <b>{{ device_summary.total_ok }} / {{ device_summary.total_ok + device_summary.total_fail }}</b></b-col>
                    <b-col class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">Текущо производство: <b>{{ device_summary.total_current | round(2) }} kW</b></b-col>
                    <b-col class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">Дневно производство: <b>{{ device_summary.total_daily | round(2) }} kWh</b></b-col>
                </b-row>
            </div>
        </div>
        <div v-if=detailed_view>
            <b-list-group v-for="device in devices" :key="device.deviceId">
                <b-list-group-item class="flex-column align-items-start mt-2">
                    <b-row>
                        <b-col class="col-md-2 col-sm-2 col-lg-2 col-xs-2 col-xl-2">
                            <h5 class="mb-1">{{ device.deviceFriendlyName }}</h5>
                        </b-col>
                        <b-col class="icon-info-box col-md-2 col-sm-2 col-lg-2 col-xs-2 col-xl-2">
                            <b-icon icon="bar-chart-fill" font-scale="1" class="d-block d-sm-block d-md-inline d-inline-lg d-inline-xl" :variant=commColor[device.commStatus]></b-icon> <span class="info-text d-block d-sm-block d-md-inline d-inline-lg d-inline-xl">{{ device.commStatus }}</span>
                        </b-col>
                        <b-col class="icon-info-box col-md-2 col-sm-2 col-lg-2 col-xs-2 col-xl-2">
                            <b-icon icon="plug-fill" font-scale="1" class="d-block d-sm-block d-md-inline d-inline-lg d-inline-xl" :variant=deviceColor[device.deviceStatus]></b-icon> <span class="info-text">{{ device.deviceStatus }}</span>
                        </b-col>
                        <b-col class="icon-info-box col-md-2 col-sm-2 col-lg-2 col-xs-2 col-xl-2">
                            <b-icon icon="lightning-fill" font-scale="1" class="d-block d-sm-block d-md-inline d-inline-lg d-inline-xl" :variant=deviceColor[device.deviceStatus]></b-icon> <span class="info-text">{{ device.active_power }} KW</span>
                        </b-col>
                        <b-col class="col-md-4 col-sm-2 col-lg-4 col-xs-2 col-xl-4 text-right no-show-on-mobile">
                            <small>Последен контакт: {{ device.lastContact | moment }}</small>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2 align-items-center justify-content-center">
                        <b-col class="col-12 col-sm-12 col-md-8 col-lg-2 col-xl-2">
                            <b-row>
                                <b-col class="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                                    <dl>
                                        <dd>Дневно производство1</dd>
                                        <dt>{{ device.e_day }} kWh</dt>
                                    </dl>
                                </b-col>
                                <b-col class="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                                    <dl>
                                        <dd>Месечно производство</dd>
                                        <dt>{{ device.e_month }} kWh</dt>
                                    </dl>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                            <LineChart :deviceId=device.deviceId :data=null />
                        </b-col>
                        <b-col class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 text-right mt-2">
                            <b-button-group>
                            <b-button size="sm" variant="outline-primary" @click="$router.push({name: 'charts', params: { id: device.deviceId }})">
                                <b-icon icon="bar-chart-steps" variant="outline-primary" font-scale="1" ></b-icon> Графики
                            </b-button>
                            <b-button  size="sm" variant="outline-primary" @click="$router.push({name: 'details', params: { id: device.deviceId, deviceData: getDeviceDataById(device.deviceId) },})">
                                <b-icon icon="card-checklist" font-scale="1"></b-icon> Детайли
                            </b-button>
                            <b-button size="sm" variant="outline-primary" @click="$router.push({name: 'reports', params: {id: device.deviceId}})">
                                <b-icon   icon="calendar2-range" font-scale="1"></b-icon> Отчети
                            </b-button>
                            <!-- <b-button v-if=isAdmin size="sm" variant="outline-danger" @click="$router.push({name: 'reports', params: {id: device.deviceId}})">
                                <b-icon   icon="calendar2-range" font-scale="1"></b-icon> Сравни
                            </b-button> -->
                            </b-button-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2 align-items-center justify-content-center">
                        <b-col class="">
                            <div v-if="device.remoteControl">
                                <h4>Контрол</h4>
                                <!-- <div>
                                    <b-button size="sm" variant="outline-danger" @click="$router.push({name: 'reports', params: {id: device.deviceId}})">
                                        <b-icon icon="stop-circle" font-scale="1"></b-icon> Спри
                                    </b-button>
                                </div>
                                <div>
                                    <b-button class="ml-2" size="sm" variant="outline-success" @click="$router.push({name: 'reports', params: {id: device.deviceId}})">
                                        <b-icon icon="play-circle" font-scale="1"></b-icon> Пусни
                                    </b-button>
                                </div> -->
                                <div>
                                    Статус на ограничителя: <b-button :variant=limitColor[device.remoteControlStatus] size="sm">{{ device.remoteControlStatus | limitStatus }}</b-button>
                                </div>
                                <div>
                                    <!-- <label for="range-1">Ограничи</label> -->
                                    <b-form-input id="range-1" :value="device.remoteLimit" @change="getUpdatedLimit(device.deviceId)" v-model="device.remoteLimit" type="range" min="0" max="100" step="10"></b-form-input>
                                    <div class="mt-2">
                                        <b-button :variant=getLimitRatioColor(device.remoteLimit) size="sm">{{ device.remoteLimit }} / 100%</b-button>
                                    
                                        <b-button v-if="device.enableApply" class="ml-2" size="sm" variant="danger" @click="setLimit(device.deviceId)">
                                            <b-icon icon="cloud-upload" font-scale="1"></b-icon> Приложи
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>
        </div>
        <div class="small">
            
        </div>
    </div>
</template>

<script>
import axios from '../../middleware/axios'
import LineChart from '../charts/LineChart'
import frontEndLineChartUser from '../charts/FrontEndLineChartUser.vue'
import frontEndBarChartUser from '../charts/FrontEndBarChartUser.vue'
import moment from 'moment'

export default {
    name: "List",

    components: {
        LineChart,
        frontEndLineChartUser,
        frontEndBarChartUser
    },
    data() {
        return {
            devices: null,
            enableApply: false,
            device_table: null,
            device_summary: {
                total_ok: 0,
                total_fail: 0,
                total_current: 0,
                total_daily: 0
            },
            limitValue: 100,
            detailed_view: true,
            fields: [
                {
                    key: 'name',
                    label: "Име"
                },
                {
                    key: 'comm_status',
                    label: "Модем"
                },
                {
                    key: 'device_status',
                    label: "У-во"
                },
                {
                    key: 'active_power',
                    label: "Текущо (kW)"
                },
                {
                    key: 'daily_power',
                    label: "За деня (kWh)"
                }
            ],
            commColor: {
                OK: "success",
                REQ_SENT: "warning",
                DEVICE_DEAD: "danger",
            },
            deviceColor: {
                "Idle Initializing": "warning",
                "Idle detecting ISO": "warning",
                "Idle detecting irradiation": "warning",
                "Idle detecting grid": "warning",
                "Starting": "warning",
                "On-grid": "success",
                "On-grid power limit": "success",
                "On-grid self derating": "success",
                "Shutdown fault": "danger",
                "Shutdown command": "danger",
                "Shutdown OVGR": "danger",
                "Shutdown communication disconnected": "danger",
                "Shutdown power limit": "danger",
                "Shutdown start manually": "danger",
                "Grid dispatch cos(phi)": "danger",
                "Grid dispatch Q-U curve": "danger",
                "Idle No irradiation": "warning",
                "Spot check": "warning",
                "Spot checking": "warning",
                "Inspecting": "warning",
                "AFCI self-check": "warning",
                "IV scanning": "warning"
            },
            limitColor: {
                CONFIRMED: "success",
                EMIT: "warning",
                COMMAND_SENT: "warning",
            },
            isLoaded: false,
            deviceCount: 0
        }
    },
    methods: {
        getDeviceDataById(deviceId) {
            let r = this.devices.find(element => element.deviceId === deviceId)
            return r;
        },
        createTableData(devices) {
            let r = []
            for(let device of devices) {

                

                let modified_comm_status = ""
                if(device.commStatus === 'DEVICE_DEAD') {
                    modified_comm_status = "Device dead"
                } else if(device.commStatus === 'REQ_SENT') {
                    modified_comm_status = "Req sent"
                } else {
                    modified_comm_status = device.commStatus
                }
                if(device.commStatus === "OK" && device.deviceStatus === "On-grid") {
                    this.device_summary.total_ok += 1
                } else {
                    this.device_summary.total_fail += 1
                }
                this.device_summary.total_current += device.active_power
                this.device_summary.total_daily += device.e_day
                r.push(
                    {
                        name: device.deviceFriendlyName,
                        comm_status: modified_comm_status,
                        device_status: device.deviceStatus,
                        active_power: device.active_power,
                        daily_power: device.e_day
                    }
                )
                
            }
            return r
        },
        rowClass(device, type) {
            if (!device || type !== 'row') return
            if (device.comm_status.includes('dead')) return 'table-danger'
            if (device.device_status === 'On-grid') return 'table-success'
            if (device.comm_status.includes('Req')) return 'table-warning'
            if (device.device_status.includes('Idle')) return 'table-warning'
            if (device.device_status.includes('Shutdown')) return 'table-danger'

        },
        getUpdatedLimit(deviceId) {
            let d = this.getDeviceDataById(deviceId);
            if(['CONFIRMED', 'FAILED'].includes(d.remoteControlStatus)) {
                d.enableApply = true;
            }
        },
        setLimit(deviceId) {
            console.log(deviceId)
            let d = this.getDeviceDataById(deviceId)
            console.log(d)
            let params = {
                remoteLimit: d.remoteLimit
            }
            console.log(params)
            this.enableApply = false;

            axios({ 
                url: 'https://solareye.ntx.bg:9091/devices/' + deviceId + '/update', 
                data: params, 
                method: 'POST'})
            .then(res => {
                console.log(res);
                if(res.data.success) {
                    d.remoteControlStatus = 'EMIT';
                    d.enableApply = false;
                }
                console.log(d);
            })
            .catch(err => {
                console.log(err);        
            })
        },
        getLimitRatioColor(limit) {
            if(limit == 100) {
                return "success";
            } else if(limit > 0) {
                return "warning";
            } else if(limit == 0) {
                return "danger";
            }
        }
    },
    mounted() {
        this.deviceCount = this.$store.state.deviceCount;

        axios({ url: 'https://solareye.ntx.bg:9091/devices', method: 'GET'})
        .then(res => {
            console.log(res.data)
            this.isLoaded = true
            this.devices = res.data.map(
                e => {
                    return {...e, enableApply: false}
                }
            )
            this.device_table = this.createTableData(res.data)
        })
        
        setInterval(() => {
            axios({ url: 'https://solareye.ntx.bg:9091/devices', method: 'GET'})
            .then(res => {
                console.log(res.data)
                this.isLoaded = true
                this.devices = res.data.map(
                    e => {
                        return {...e, enableApply: false}
                    }
                )
                this.device_table = this.createTableData(res.data)
            })
        }, 60000)
    },
    computed: {
      isAdmin() {
        return this.$store.state.role === 'admin'
      }
    },
    filters: {
        moment: function (date) {
            return moment(date).fromNow();
        },
        round: function(value, decimals) {
            if(!value) {
                value = 0;
            }

            if(!decimals) {
                decimals = 0;
            }

            value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
            return value;
        },
        limitStatus: function(value) {
            if(value == 'CONFIRMED') {
                return "Потвърден";
            } else if(value == 'EMIT') {
                return "Изпратена инструкция";
            } else if(value == 'COMMAND_SENT') {
                return "Изпратена инструкция";
            }
        }
    }
}
</script>

<style scoped>
dl {
    font-family: 'Avenir LT Pro', Helvetica, Arial, sans-serif;
}
dd {
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 1px;
    color: rgb(144, 149, 151);
    margin-bottom: 3px;
}
dt {
    font-size:12px;
    font-weight:700;
    color: rgb(38, 50, 56);
}
.info-text {
    font-weight:700;
    color: rgb(38, 50, 56);
}
.small {
    max-width: 600px;
    max-height: 200px;
    margin:  150px auto;
}

.lineChart-skeleton {
    height: 120px;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .no-show-on-mobile {
        display: none;
    }
    .icon-info-box > .b-icon {
        margin: auto;
    }
    .icon-info-box > span {
        text-align: center;
    }
}

</style>